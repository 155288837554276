@keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .slide {
    animation: slide 1s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  