@import url("https://use.typekit.net/tjr3qmq.css");
@import url('https://fonts.cdnfonts.com/css/futurablack-BT');
@import url('https://fonts.cdnfonts.com/css/helvetica-rounded-lt-std');

@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'futura';
  src: local('Futura'), url(./fonts/futura-bold-it.ttf) format('ttf');
}

@font-face {
  font-family: 'avenir';
  src: local('Avenir'), url(./fonts/avenir.ttf) format('ttf');
}

@font-face {
  font-family: 'avenirNext';
  src: local('AvenirNext'), url(./fonts/avenir_next.ttf) format('ttf');
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
